import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Button } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';


import './castingItem.scss';

const SingleCasting = ({ item, fullName }) => {
    return (
        <div className="single-casting">
            <h2 className="casting-title">
                {item.title}
                {item.is_pinned === 1 ? (
                    <span className="pinned-icon">
                        <i className="fas fa-map-pin" />
                        {' '}
                        Pinned
                    </span>
                ) : false
                }
            </h2>
            {/*<p className="casting-subtitle">*/}
            {/*    Posted*/}
            {/*    {' '}*/}
            {/*    {moment(item.date_published).format('MM-DD-YYYY')}*/}
            {/*</p>*/}
            <div className="summary-block">
                <table>
                    <tbody>
                        <tr>
                            <td><i className="fas fa-map-marker-alt" /></td>
                            <td>{item.location}</td>
                        </tr>
                        <tr>
                            <td><i className="far fa-calendar" /></td>
                            <td>{item.casting_date}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <br />
            <h3>Casting Details</h3>
            <p>{item.job_details}</p>

            <h3>Description/Notes</h3>
            <p>{item.job_requirements}</p>
            <p className="button-block">
                <Button htmlType="submit" className="theme-primary-button wide jelly" size="large" href={`mailto:${item.responsible_email}?subject=${fullName} - ${item.title}`}>
                    Apply
                </Button>
            </p>
        </div>
    );
}

SingleCasting.defaultProps = {
    fullName: ''
};

SingleCasting.propTypes = {
    item: PropTypes.object.isRequired,
    fullName: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleCasting);
