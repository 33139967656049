import { Record } from 'immutable';
import { findIndex } from 'underscore';

const {
    DELETE_TOKEN_REQUEST,
    DELETE_TOKEN_SUCCESS,

    SAVE_TOKEN_REQUEST,
    SAVE_TOKEN_SUCCESS,

    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,

    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,

    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILURE,

    UPLOAD_IMAGE_REQUEST,
    UPLOAD_IMAGE_SUCCESS,
    UPLOAD_IMAGE_FAILURE,

    REMOVE_IMAGE_REQUEST,
    REMOVE_IMAGE_SUCCESS,
    REMOVE_IMAGE_FAILURE,

    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,

} = require('./userActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    isLoggedIn: false,
    loginUser: '',
    userSettings: {},
    singleUser: {},
    options: {}
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function userReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case LOGIN_REQUEST:
    case DELETE_TOKEN_REQUEST:
    case SAVE_TOKEN_REQUEST:
    case LOGOUT_REQUEST:
    case UPDATE_PROFILE_REQUEST:
    case UPLOAD_IMAGE_REQUEST:
    case REMOVE_IMAGE_REQUEST:
    case RESET_PASSWORD_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case SAVE_TOKEN_SUCCESS:
    case LOGIN_SUCCESS:
        return state.set('isFetching', false)
            .set('loginUser', (payload && payload.data && payload.data.username ? payload.data.username : 'Logged In User'))
            .set('userSettings', (payload && payload.data && payload.data.settings ? payload.data.settings : {}))
            .set('singleUser', (payload && payload.data && payload.data.user ? payload.data.user : {}))
            .set('options', (payload && payload.data && payload.data.options ? payload.data.options : {}))
            .set('isLoggedIn', true);

    case UPDATE_PROFILE_SUCCESS: {
        const singleUser = state.singleUser;

        if (payload && payload.data && payload.data.profile) {
            singleUser.profile = payload.data.profile;
        }

        return state.set('isFetching', false)
            .set('singleUser', singleUser);
    }

    case UPLOAD_IMAGE_SUCCESS: {
        const singleUser = state.singleUser;

        if (payload && payload.data && payload.data.imageData) {
            singleUser.profilePicturesList.push(payload.data.imageData);
        }

        return state.set('isFetching', false)
            .set('singleUser', singleUser);
    }

    case REMOVE_IMAGE_SUCCESS: {
        const singleUser = state.singleUser;
        const imageId = parseInt(payload.imageId, 10);


        const singleImageKey = findIndex(singleUser.profilePicturesList, val => val.id === imageId);
        if (singleImageKey !== -1) {
            singleUser.profilePicturesList.splice(singleImageKey, 1);
        }

        return state.set('isFetching', false)
            .set('singleUser', singleUser);
    }

    case DELETE_TOKEN_SUCCESS:
    case LOGOUT_SUCCESS:
        return state.set('isFetching', false)
            .set('loginUser', '')
            .set('singleUser', {})
            .set('options', {})
            .set('isLoggedIn', false);

    case RESET_PASSWORD_SUCCESS:
        return state.set('isFetching', false);

    case LOGIN_FAILURE:
    case LOGOUT_FAILURE:
    case UPDATE_PROFILE_FAILURE:
    case UPLOAD_IMAGE_FAILURE:
    case REMOVE_IMAGE_FAILURE:
    case RESET_PASSWORD_FAILURE:
        return state.set('isFetching', false)
            .set('isLoggedIn', false)
            .set('error', payload);

    default:
        return state;
    }
}
