import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { isEmpty } from 'underscore';

import Loading from '../../elements/Loading';
import Pagination from '../../elements/Pagination';
import AgencyItem from './elements/AgencyItem';
import CastingItem from "../Castings/elements/CastingItem";

export default function () {
    const { agency, user } = this.props;

    const full_name = user && user.singleUser && user.singleUser.profile && user.singleUser.profile.full_name? user.singleUser.profile.full_name: '';

    return (
        <div className="ui container general-flex-content">
            {agency.isFetching ? <Loading /> : null}

            {isEmpty(agency.agencies) ?
                <h3 className="no-data-title">There are no agencies for you at the moment...</h3>
                :
                (
                    <div className="pagination-block">
                        <Pagination data={agency.agencies} pageSize={10}>
                            <AgencyItem fullName={full_name} />
                        </Pagination>
                    </div>
                )
            }
        </div>
    );
}
