import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { message } from 'antd';
import gallery from './gallery/galleryReducer';
import global from './global/globalReducer';
import user from './user/userReducer';
import casting from './casting/castingReducer';
import agency from './agency/agencyReducer';

/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */
const appReducers = combineReducers({
    // ... your other reducers here ...
    gallery,
    global,
    user,
    casting,
    agency,
    form: formReducer
});

const rootReducer = (state, action) => {
    // ... your other reducers here ...
    if ((action.type).indexOf('_FAILURE') !== -1) {
        message.error(action.payload.message ? action.payload.message : action.payload);
    }

    return appReducers(state, action);
};


export default rootReducer;
