import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import { Row, Col, Select } from 'antd';
import moment from 'moment';


class AntDateTimePickerDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = this.buildState(props.input.value);
    }

    componentWillReceiveProps(props) {
        if (props.input.value !== this.props.input.value) {
            this.setState(this.buildState(props.input.value));
        }
    }

    buildState(value) {
        return value ? {
            date: parseInt(moment(value).format('D'), 10),
            month: parseInt(moment(value).format('M'), 10),
            year: parseInt(moment(value).format('Y'), 10)
        } : {
            date: '',
            month: '',
            year: ''
        };
    }

    update(state) {
        this.setState(state);

        const { date, month, year } = { ...this.state, ...state };
        const { onChange, value } = this.props.input;

        if (date && month && year) {
            onChange(moment(`${year}-${month}-${date} 00:00:00`, 'YYYY-M-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:00'));
        } else if (value) {
            onChange(null);
        }
    }

    getOptions(start, end, type = '') {
        const options = [];

        const Option = Select.Option;

        for (let i = start; i <= end; i++) {
            options.push(<Option key={`drop-down-${type}-${i}`} value={i}>{i}</Option>);
        }

        return options;
    }

    render() {
        const { label, input: { name } } = this.props;
        const { date, month, year } = this.state;
        const thisYear = new Date().getFullYear();

        const maxDaysCurrentMonthHave = moment(`${year}-${month}-${date} 00:00:00`, 'YYYY-M-DD HH:mm:ss').daysInMonth();

        return (
            <div>
                <Row>
                    <Col span={24} style={{ paddingBottom: 10, paddingTop: 5 }}>
                        <label htmlFor={`${name  }-day`}>
                            {label}
                        </label>
                    </Col>
                    <Col span={7} style={{ paddingRight: 15 }}>
                        <Select onChange={value => this.update({ month: value })} value={month} name={`${name  }-month`}>
                            {this.getOptions(1, 12, 'month')}
                        </Select>
                    </Col>
                    <Col span={7} style={{ paddingRight: 15 }}>
                        <Select onChange={(value) => { this.update({ date: value }); }} value={date} name={`${name  }-day`}>
                            {this.getOptions(1, maxDaysCurrentMonthHave, 'day')}
                        </Select>
                    </Col>
                    <Col span={10}>
                        <Select onChange={value => this.update({ year: value })} value={year} name={`${name  }-year`}>
                            {this.getOptions(thisYear - 90, thisYear, 'year')}
                        </Select>
                    </Col>
                </Row>
            </div>
        );
    }
}

AntDateTimePickerDropDown.defaultProps = {
    placeholder: 'Select date & time',
    showTime: true,
    label: '',
    initialiseNull: false,
    labelStyle: {},
    style: {}
};

AntDateTimePickerDropDown.propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string,
    showTime: PropTypes.bool,
    initialiseNull: PropTypes.bool,
    labelStyle: PropTypes.object,
    style: PropTypes.object,

};

export default AntDateTimePickerDropDown;
