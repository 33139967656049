import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import { Select } from 'antd';

class AntSelect extends Component {

    updateValue(currentValue) {
        console.log('currentValue')
        const { input: { value, onChange } } = this.props;
        onChange(currentValue);
    }

    render() {
        const { input: { value, onChange, props, name }, width, label, elements, placeholder, required, allowClear, style } = this.props;
        const Option = Select.Option;

        const items = [];
        elements.map((item, k) => {
            items.push(<Option key={`ant-select-${name}-${item.value}`} value={item.value}>{item.text}</Option>);
        });

        return (

            <div className="performance-license-select-wrapper">

                {!isEmpty(label) ?
                    <div className="ant-form-item-label"><label title="Event date">{label}</label></div>
                    : null }
                <Select
                    className="performance-license-select full-width-mobile"
                    style={{ width }, style }
                    placeholder={placeholder}
                    onChange={this.updateValue.bind(this)}
                    required
                    allowClear={allowClear}
                    value={value} >
                    {items}
                </Select>
            </div>
        );
    }
}

AntSelect.defaultProps = {
    placeholder: 'Please select',
    required: true,
    allowClear: true,
    label: '',
    width: '100%',
    style: {}
};

AntSelect.propTypes = {
    placeholder: PropTypes.string,
    width: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    elements: PropTypes.array.isRequired,
    allowClear: PropTypes.bool,
};

export default AntSelect;
