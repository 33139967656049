import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DOM from './castings';
import * as globalActions from '../../core/global/globalActions';
import * as castingActions from '../../core/casting/castingActions';

class Castings extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentDidMount() {
        const { actions } = this.props;

        actions.getCastingsRequest();
    }

    render() {
        return this.view();
    }
}

Castings.propTypes = {
    actions: PropTypes.object.isRequired,
    casting: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        casting: state.casting,
        user: state.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...globalActions,
            ...castingActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Castings);
