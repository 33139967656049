import { all } from 'redux-saga/effects';
import gallerySaga from './gallery/gallerySaga';
import userSaga from './user/userSaga';
import castingSaga from './casting/castingSaga';
import agencySaga from './agency/agencySaga';

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        gallerySaga(),
        userSaga(),
        castingSaga(),
        agencySaga()
    ]);
}
