import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { reduxForm, Field } from 'redux-form';

import AntInput from '../../../elements/AntInput';
import AntSelect from '../../../elements/AntSelect';
import ReactSelect from '../../../elements/ReactSelect';
import AntDateTimePicker from '../../../elements/AntDateTimePicker';
import AntDateTimePickerDropDown from '../../../elements/AntDateTimePickerDropDown';
import AntTextArea from '../../../elements/AntTextArea';
import ProfileImageUpload from '../elements/ProfileImageUpload';
import ProfileImagesList from '../elements/ProfileImagesList';
import { isEmpty } from 'underscore';

const ProfileForm = ({
    handleSubmit, submitting, options, imagesCount
}) => {
    if (isEmpty(options)) {
        return null;
    }

    const genderOptions = Object.keys(options.gender_options).map(key => (
        { text: options.gender_options[key], value: key }
    ));

    const hairColorOptions = Object.keys(options.hair_color_options).map(key => (
        { text: options.hair_color_options[key], value: key }
    ));

    const heightOptions = Object.keys(options.height_options).map(key => (
        { text: options.height_options[key], value: parseInt(key, 10) }
    ));

    const raceOptions = Object.keys(options.race_options).map(key => (
        { text: options.race_options[key], value: key }
    ));

    const locationOptions = Object.keys(options.location_options).map(key => (
        { label: options.location_options[key], value: key }
    ));

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-block profile-form">
                <h2 style={{ textAlign: 'center' }}>Your information</h2>
                <span
                    className="performance-license-input-text ant-input-affix-wrapper"
                    style={{ width: '100%' }}>
                    <Field
                        style={{ marginBottom: 10 }}
                        component={AntInput}
                        type="text"
                        name="full_name"
                        required
                        label="Full name" />
                </span>
                <span
                    className="performance-license-input-text ant-input-affix-wrapper"
                    style={{ width: '100%' }}>
                    <Field
                        style={{ marginBottom: 10 }}
                        component={AntInput}
                        type="text"
                        name="phone"
                        required
                        label="Phone" />
                </span>
                <span
                    className="performance-license-input-text ant-input-affix-wrapper"
                    style={{ width: '100%' }}>
                    <Field
                        style={{ marginBottom: 10 }}
                        name="gender"
                        component={AntSelect}
                        width="100%"
                        label="Gender"
                        id="gender"
                        allowClear={false}
                        required
                        elements={genderOptions} />
                </span>
                <span
                    className="performance-license-input-text ant-input-affix-wrapper"
                    style={{ width: '100%' }}>
                    <Field
                        style={{ marginBottom: 10 }}
                        name="hair_color"
                        component={AntSelect}
                        width="100%"
                        label="Hair color"
                        id="hair_color"
                        allowClear={false}
                        required
                        elements={hairColorOptions} />
                </span>
                <span
                    className="performance-license-input-text ant-input-affix-wrapper"
                    style={{ width: '100%' }}>
                    <Field
                        style={{ marginBottom: 10 }}
                        name="race"
                        component={AntSelect}
                        width="100%"
                        label="Race"
                        id="race"
                        allowClear={false}
                        required
                        elements={raceOptions} />
                </span>
                <span
                    className="performance-license-input-text ant-input-affix-wrapper"
                    style={{ width: '100%' }}>
                    <Field
                        style={{ marginBottom: 10 }}
                        name="height"
                        component={AntSelect}
                        width="100%"
                        label="Height"
                        id="height"
                        allowClear={false}
                        required
                        elements={heightOptions} />
                </span>
                <span className="performance-license-input-text ant-input-affix-wrapper">
                    <Field
                        component={AntDateTimePickerDropDown}
                        key="date_of_birth"
                        name="date_of_birth"
                        showTime={false}
                        required
                        labelStyle={{ width: '100%', textAlign: 'left' }}
                        style={{ width: '100%' }}
                        label="DOB (mm/dd/yyyy)"
                        className="performance-license-input-text event-field" />
                </span>
                <span
                    className="performance-license-input-text ant-input-affix-wrapper"
                    style={{ width: '100%' }}>
                    <Field
                        style={{ marginBottom: 10 }}
                        name="locationList"
                        component={ReactSelect}
                        width="100%"
                        label="Location"
                        id="location"
                        isMulti={true}
                        allowClear={false}
                        elements={locationOptions} />
                </span>
                <span
                    className="performance-license-input-text ant-input-affix-wrapper"
                    style={{ width: '100%' }}>
                    <Field
                        style={{ marginBottom: 10 }}
                        component={AntTextArea}
                        type="textarea"
                        name="other_info"
                        placeholder="E.g. Waist size, Eye color, Chest size, Arm length, shoes size, Hair length"
                        required
                        label="Other comp-card information" />
                </span>
            </div>
            <div className="form-block" style={{ marginTop: 30 }}>
                <h2 style={{ textAlign: 'center' }}>Your images</h2>
                <h4 style={{ textAlign: 'center' }}>
                    Due to the design of our downloadable comp-card, for best results
                    <br />
                    we would recommend you only upload portrait images.
                </h4>
                <ProfileImagesList />

                {imagesCount < 5 ? (
                    <p style={{ textAlign: 'center' }}>
                        <Field
                            component={ProfileImageUpload}
                            multiple={false}
                            name="pictureId"
                            subtitle="Add Image" />
                    </p>
                )
                    : <h4 style={{ textAlign: 'center' }}>You have uploaded maximum number of images</h4>
                }

                <p style={{ textAlign: 'center' }}>Max file size per image: 4Mb</p>
            </div>
            <div style={{ textAlign: 'center', marginTop: 30 }}>
                <p style={{ textAlign: 'center' }}>
                    In saving your profile information, you understand that casting
                    <br />
                    notices are from third-parties and subject to your own independent
                    {' '}
                    <br />
                    verification. Never accept prepayment for a project.
                    {' '}
                    <br />
                    Latitude does not promise or facilitate employment.
                </p>
                <Button htmlType="submit" disabled={submitting} className="theme-primary-button" size="large">
                    Accept and Save Profile
                </Button>
            </div>
        </form>
    );
};

ProfileForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired,
    imagesCount: PropTypes.number.isRequired,
};

export default reduxForm({
    form: 'ProfileForm'
})(ProfileForm);
