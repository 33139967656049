import { Record } from 'immutable';
import { findIndex } from 'underscore';

const {
    GET_CASTINGS_REQUEST,
    GET_CASTINGS_SUCCESS,
    GET_CASTINGS_FAILURE,

} = require('./castingActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    castings: []
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function userReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case GET_CASTINGS_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);


    case GET_CASTINGS_SUCCESS: {
        return state.set('isFetching', false)
            .set('castings', payload.data);
    }

    case GET_CASTINGS_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;
    }
}
