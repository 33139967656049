import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DOM from './agencies';
import * as globalActions from '../../core/global/globalActions';
import * as agencyActions from '../../core/agency/agencyActions';

class Agencies extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.getAgenciesRequest();
    }

    render() {
        return this.view();
    }
}

Agencies.propTypes = {
    actions: PropTypes.object.isRequired,
    agency: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        agency: state.agency,
        user: state.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...globalActions,
            ...agencyActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Agencies);
