import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { isEmpty } from 'underscore';

import Loading from '../../elements/Loading';
import Pagination from '../../elements/Pagination';
import CastingItem from './elements/CastingItem';

export default function () {
    const { casting, user } = this.props;

    const full_name = user && user.singleUser && user.singleUser.profile && user.singleUser.profile.full_name? user.singleUser.profile.full_name: '';

    return (
        <div className="ui container general-flex-content">
            {casting.isFetching ? <Loading /> : null}

            {isEmpty(casting.castings) ?
                <h3 className="no-data-title">There are no castings for you at the moment...</h3>
                :
                (
                    <div className="pagination-block">
                        <Pagination data={casting.castings} pageSize={10}>
                            <CastingItem fullName={full_name} />
                        </Pagination>
                    </div>
                )
            }
        </div>
    );
}
