import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'underscore';

const Breadcrumb = ({ data }) => (
    <div className="ui container">
        <div className="ui breadcrumb">
            <Link className="section" to="/">Home</Link>
            {!_.isUndefined(data)
                ? data.map((item, index) => (
                    <span key={index}>
                        <i className="right chevron icon divider" />
                        {!_.isUndefined(item.path)
                            ? <Link className="section" to={item.path}>{item.title}</Link>
                            : <div className="section active">{item.title}</div>
                        }
                    </span>
                ))
                : null
            }
        </div>
    </div>
);

Breadcrumb.defaultProptypes = {
    data: []
}

Breadcrumb.propTypes = {
    data: PropTypes.array
};

export default Breadcrumb;
