import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { message } from 'antd';

import DOM from './profile';
import * as globalActions from '../../core/global/globalActions';
import * as userActions from '../../core/user/userActions';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentDidMount() {
        const { actions } = this.props;

        // set breadcrumb
        actions.setBreadcrumb([{ title: 'About' }]);
    }

    updateProfile(data) {
        const { actions } = this.props;

        const userAge = (moment(data.date_of_birth).diff(moment(), 'years')) * -1;
        if (userAge < 18) {
            message.warning('Users under 18 must use our service under the supervision of a parent or legal guardian');
        }

        actions.updateProfileRequest(data);
    }

    render() {
        return this.view();
    }
}

Profile.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        user: state.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...globalActions,
            ...userActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
