import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pagination as AntPagination } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'underscore';

class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: null,
            pageCount: null
        };
    }

    componentWillMount() {
        const startingPage = this.props.startingPage ? this.props.startingPage : 1;
        const data = this.props.data;
        const pageSize = this.props.pageSize;
        let pageCount = parseInt(data.length / pageSize);
        if (data.length % pageSize > 0) {
            pageCount += 1;
        }
        this.setState({
            currentPage: startingPage,
            pageCount
        });
    }

    createPaginatedData() {
        const data = this.props.data;
        const pageSize = this.props.pageSize;
        const currentPage = this.state.currentPage;
        const upperLimit = currentPage * pageSize;
        const dataSlice = data.slice((upperLimit - pageSize), upperLimit);
        return dataSlice;
    }

    setCurrentPage(num) {
        this.setState({ currentPage: num });
    }

    createControls() {
          let controls = [];
          const pageCount = this.state.pageCount;
          for (let i = 1; i <= pageCount; i++) {
            const baseClassName = 'pagination-controls__button';
            const activeClassName = i === this.state.currentPage ? `${baseClassName}--active` : '';
            controls.push(
              <div
                className={`${baseClassName} ${activeClassName}`}
                onClick={() => this.setCurrentPage(i)}
              >
                {i}
              </div>
            );
          }
          return controls;
    }

    render() {
        const data = this.createPaginatedData();

        return (
            <div className="pagination">
                <div className="pagination-results">
                    {data.map(item => (
                        React.cloneElement(this.props.children, { item, key: `item-${item.id}` })
                    ))}
                </div>
                <div className="pagination-controls">
                    <AntPagination onChange={(number) => this.setCurrentPage(number)} current={this.state.currentPage} pageSize={this.props.pageSize} total={this.props.data.length} />
                </div>
            </div>
        );
    }
}

Pagination.defaultProps = {
    pageSize: 5,
    startingPage: 1,
    childrenElementName: 'item'
};

Pagination.propTypes = {
    data: PropTypes.array.isRequired,
    pageSize: PropTypes.number,
    startingPage: PropTypes.number,
    childrenElementName: PropTypes.string
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        casting: state.casting
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
