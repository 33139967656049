/* global: localStorage */
import {
    call, put, all, takeLatest
} from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth } from '../utils/api';


import {
    getAgenciesSuccess,
    getAgenciesFailure
} from './agencyActions';

const {
    GET_AGENCIES_REQUEST
} = require('./agencyActions').constants;



function* getAgencies(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'agency/get-agencies',
            body: action.payload
        });

        yield put(getAgenciesSuccess(response));
    } catch (e) {
        yield put(getAgenciesFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield all([
        takeLatest(GET_AGENCIES_REQUEST, getAgencies),
    ]);
}
