/* global localStorage: true */
import React, { Component } from 'react';
import {
    Switch, Route, Redirect
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Layout, message } from 'antd';
import lockr from 'lockr';
import storage from 'redux-persist/lib/storage';
import _ from 'underscore';

import AppHeader from '../../components/Header';
import Login from '../../pages/Login';
import About from '../../pages/About';
import Contact from '../../pages/Contact';

import PageCastings from '../../pages/Castings';
import PageAgencies from '../../pages/Agencies';
import PageProfile from '../../pages/Profile';

import NotFound from '../../pages/NotFound';

import Breadcrumb from '../../elements/Breadcrumb';
import * as globalActions from '../global/globalActions';

const {
    Content
} = Layout;

const {
    APP_NAME,
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
    LOCALSTORAGE_EXPIRES
} = require('../../core/constants').default;

const onlyAuthorisedAllowed = () => {
    const response = lockr.get(AUTH_KEY);
    if (!_.isUndefined(response)) {
        const authDate = lockr.get(LOCALSTORAGE_EXPIRES_KEY);
        if (!_.isUndefined(authDate)) {
            const aDate = new Date(authDate);
            const aNow = new Date();
            // if (aNow < aDate) {
            //     aNow.setDate(aNow.getDate() + 1);
            // }
            const milliseconds = aNow - aDate;
            const difference = Math.floor(milliseconds / 1000 / 60);
            if (difference >= LOCALSTORAGE_EXPIRES) {
                lockr.rm(LOCALSTORAGE_EXPIRES_KEY);
                lockr.rm(AUTH_KEY);
                message.error('Session expired. Please login again.');
                localStorage.clear();
                storage.removeItem('persist:root');
                lockr.flush();
                // redirect to login page
                // history.push('/');
                return false;
            }
        } else {
            lockr.rm(LOCALSTORAGE_EXPIRES_KEY);
            lockr.rm(AUTH_KEY);
            message.error('Session expired. Please login again.');
            localStorage.clear();
            storage.removeItem('persist:root');
            lockr.flush();
            // redirect to login page
            // history.push('/');
            return false;
        }
    } else {
        storage.removeItem('persist:root');
        lockr.rm(LOCALSTORAGE_EXPIRES_KEY);
        lockr.rm(AUTH_KEY);
        lockr.flush();
        localStorage.clear();
        // redirect to login page
        // history.push('/');
        return false;
    }
    return true;
};

const DefaultLayout = props => (
    <div id="app">
        <Helmet
            title="App"
            titleTemplate={`%s: ${APP_NAME}`}
            defaultTitle={`${APP_NAME}`}
            meta={[
                { name: 'description', content: `${APP_NAME} website` },
                { property: 'og:type', content: 'website' }
            ]} />
        <Switch>
            <Route exact path="/" component={Login} />
            <Route
                exact
                path="/logout"
                render={() => {
                    // Session expired, remove persist store and localStorage
                    storage.removeItem('persist:root');
                    lockr.flush();
                    return (<Redirect to="/" />);
                }} />
            <Route path="/about" component={About} />
            <Redirect to={{
                state: { error: true }
            }} />
        </Switch>
    </div>
);

const LoggedInLayout = (props) => {
    const { breadcrumb } = props;

    return (
        <div id="app">
            <Layout>
                <AppHeader {...props} />
                <Content style={{ backgroundColor: '#ffffff' }}>
                    <Helmet
                        title="App"
                        titleTemplate={`%s: ${APP_NAME}`}
                        defaultTitle={`${APP_NAME}`}
                        meta={[
                            { name: 'description', content: `${APP_NAME} website` },
                            { property: 'og:type', content: 'website' }
                        ]} />
                    <Switch>
                        <Route exact path="/admin/contact" render={props => (onlyAuthorisedAllowed() ? <Contact {...props} /> : <Redirect to="/logout" />)} />
                        <Route exact path="/admin/castings" render={props => (onlyAuthorisedAllowed() ? <PageCastings {...props} /> : <Redirect to="/logout" />)} />
                        <Route exact path="/admin/agencies" render={props => (onlyAuthorisedAllowed() ? <PageAgencies {...props} /> : <Redirect to="/logout" />)} />
                        <Route exact path="/admin/profile" render={props => (onlyAuthorisedAllowed() ? <PageProfile {...props} /> : <Redirect to="/logout" />)} />
                        <Redirect to={{
                            state: { error: true }
                        }} />
                    </Switch>
                </Content>
            </Layout>
        </div>
    );
};

class GlobalErrorSwitch extends Component {
    previousLocation = this.props.location

    componentWillUpdate(nextProps) {
        const { location } = this.props;

        if (nextProps.history.action !== 'POP'
            && (!location.state || !location.state.error)) {
            this.previousLocation = this.props.location;
        }
    }

    render() {
        const { location } = this.props;
        const isError = !!(
            location.state
            && location.state.error
            && this.previousLocation !== location // not initial render
        );

        return (
            <div>
                {
                    isError
                        ? <Route component={NotFound} />
                        : (
                            <Switch location={isError ? this.previousLocation : location}>
                                <Route path="/admin" component={LoggedInLayout} />
                                <Route path="/" component={DefaultLayout} />
                            </Switch>
                        )}
            </div>
        );
    }
}

class Routes extends Component {
    render() {
        return <Route component={GlobalErrorSwitch} />;
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        breadcrumb: state.global.breadcrumb
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...globalActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
