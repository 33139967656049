import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import Select from 'react-select';

class ReactSelect extends Component {

    updateValue(currentValue) {
        const { input: { value, onChange } } = this.props;

        // check if original value contains all
        const wasAll = value.filter((val) => val == 'All').length > 0;

        // check if current value contains all
        const valueAll = currentValue.filter((val) => val.value == 'All');
        const valueNotAll = currentValue.filter((val) => val.value != 'All');
        const isAll = valueAll.length > 0;

        if (isAll) {
            if (!wasAll) {
                onChange(valueAll.map((val) => val.value));
            } else {
                onChange(valueNotAll.map((val) => val.value));
            }
        } else {
            onChange(currentValue.map((val) => val.value));
        }
    }

    render() {
        const { input: { value },width, label, elements, placeholder, allowClear, name, style, isMulti } = this.props;

        const props = {};
        if (!isEmpty(value)) {
            props.value = Object.values(value).map((val) => { return { label: val, value: val } });
        }

        return (
            <div className="performance-license-select-wrapper">
                {!isEmpty(label) ?
                    <div className="ant-form-item-label"><label title="{name}">{label}</label></div>
                    : null }
                <Select
                    name={name}
                    options={elements}
                    isMulti={isMulti}
                    isClearable={allowClear}
                    placeholder={placeholder}
                    width={width}
                    style={style}
                    onChange={this.updateValue.bind(this)}
                    { ...props}
                />
            </div>
        );
    }
}

ReactSelect.defaultProps = {
    placeholder: 'Please select',
    required: true,
    allowClear: true,
    label: '',
    width: '100%',
    style: {}
};

ReactSelect.propTypes = {
    placeholder: PropTypes.string,
    width: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    elements: PropTypes.array.isRequired,
    allowClear: PropTypes.bool,
};

export default ReactSelect;
