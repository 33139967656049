import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';
import DOM from './loginForm';
import * as userActions from '../../core/user/userActions';

class UserLoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalForgetVisible: false,
            termsModalOpen: false,
        };
        this.view = DOM;
    }

    setModalForgetVisible(modalForgetVisible) {
        this.setState({ modalForgetVisible });
    }

    toggleTermsModal = status => this.setState({ termsModalOpen: status });
    togglePasswordModal = status => this.setState({ modalForgetVisible: status });

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(['email', 'password'], (err, values) => {
            if (!err) {
                const { actions } = this.props;
                actions.loginRequest({
                    username: values.email,
                    password: values.password
                });
            }
        });
    };

    handleForgotsubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(['emailForgotten'], (err, values) => {
            if (!err) {
                const { actions } = this.props;
                this.togglePasswordModal(false);
                actions.resetPasswordRequest({
                    email: values.emailForgotten
                });
            }
        });
    };

    render() {
        return this.view();
    }
}

UserLoginForm.propTypes = {
    form: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
};

const LoginForm = Form.create()(UserLoginForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
