/* global: localStorage */
/* global FormData:true */
import {
    call, put, takeEvery, all, takeLatest
} from 'redux-saga/effects';
import { message } from 'antd';
import lockr from 'lockr';
import { fetchApi, fetchApiAuth } from '../utils/api';
import history from '../utils/history';


import {
    saveTokenSuccess,
    deleteTokenSuccess,

    loginSuccess,
    loginFailure,

    logoutSuccess,
    logoutFailure,

    updateProfileSuccess,
    updateProfileFailure,

    uploadImageSuccess,
    uploadImageFailure,

    removeImageSuccess,
    removeImageFailure,

    resetPasswordSuccess,
    resetPasswordFailure
} from './userActions';

const {
    AUTH_KEY,
    API_LOGIN_KEY,
    LOCALSTORAGE_EXPIRES_KEY
} = require('../constants').default;

const {
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    UPDATE_PROFILE_REQUEST,
    UPLOAD_IMAGE_REQUEST,
    REMOVE_IMAGE_REQUEST,
    RESET_PASSWORD_REQUEST,
} = require('./userActions').constants;

function* deleteSessionToken() {
    lockr.rm(AUTH_KEY);
    lockr.rm(API_LOGIN_KEY);
    yield put(deleteTokenSuccess());
}

function* saveSessionToken(action) {
    const authDate = new Date();
    lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
    lockr.set(AUTH_KEY, action.data.auth_token);
    yield put(saveTokenSuccess());
}

function* makeLoginRequest(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'auth/login',
            body: { username: action.payload.username, password: action.payload.password }
        });
        yield call(saveSessionToken, response);
        yield put(loginSuccess(response));
        history.push('/admin/castings');
    } catch (e) {
        yield put(loginFailure(e.response ? e.response.data.message : e));
    }
}

function* updateProfile(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'post',
            url: 'user/update-profile',
            body: action.payload
        });

        message.success('You have succesfully updated profile');

        yield put(updateProfileSuccess(response));
    } catch (e) {
        yield put(updateProfileFailure(e.response ? e.response.data.message : e));
    }
}

function* uploadImage(action) {
    try {
        const body = new FormData();
        body.append('image', action.payload);

        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'user/upload-image',
            body
        });

        message.success('Succesfully uploaded image!');
        yield put(uploadImageSuccess(response));
    } catch (e) {
        yield put(uploadImageFailure(e.response ? e.response.data.message : e));
    }
}

function* removeImage(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'user/remove-image',
            body: action.payload
        });

        yield put(removeImageSuccess(action.payload));
    } catch (e) {
        yield put(removeImageFailure(e.response ? e.response.data.message : e));
    }
}

function* resetPassword(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'auth/reset-password',
            body: action.payload
        });

        message.success('Check your email for further instructions.')
        yield put(resetPasswordSuccess(response));
    } catch (e) {
        yield put(resetPasswordFailure(e.response ? e.response.data.message : e));
    }
}

function* logoutRequest() {
    yield put(logoutSuccess());
    localStorage.clear();
    history.push('/');
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield all([
        takeEvery(LOGIN_REQUEST, makeLoginRequest),
        takeEvery(LOGOUT_REQUEST, logoutRequest),
        takeLatest(UPDATE_PROFILE_REQUEST, updateProfile),
        takeLatest(UPLOAD_IMAGE_REQUEST, uploadImage),
        takeLatest(REMOVE_IMAGE_REQUEST, removeImage),
        takeLatest(RESET_PASSWORD_REQUEST, resetPassword),
    ]);
}
