import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import { DatePicker } from 'antd';
import moment from 'moment';


class AntDateTimePicker extends Component {


    onChange(value2) {
        const { input: { onChange } } = this.props;
        onChange(value2.format('YYYY-MM-DD HH:mm:00'));
    }

    onOk(value2) {
        const { input: { value, onChange } } = this.props;
        onChange(value);
    }


    render() {
        const { input: { value, onChange, props }, elements, label, showTime, placeholder, defaultTime, dateFormat, initialiseNull, labelStyle, style } = this.props;

        let currentDate = moment();

        if (initialiseNull) {
            currentDate = null;
        }

        if (!isEmpty(value)) {
            currentDate = moment(value);
        }

        let timeFormat = false;
        if (showTime) {
            timeFormat = {
                format: 'HH:mm'
            };
        }

        return (
            <div>
                {!isEmpty(label) ?
                    <div className="ant-form-item-label" style={labelStyle}>
                        <label title="Event date">{label}</label>
                    </div>
                : null }

                <DatePicker
                    style={style}
                    className="performance-license-input-text event-field ant-calendar-picker"
                    showTime={timeFormat}
                    format={dateFormat}
                    placeholder={placeholder}
                    onChange={this.onChange.bind(this)}
                    onOk={this.onOk.bind(this)}
                    allowClear={false}
                    defaultValue={currentDate} />
            </div>
        );
    }
}

AntDateTimePicker.defaultProps = {
    placeholder: 'Select date & time',
    defaultTime: '00:00',
    dateFormat: 'DD-MM-YYYY HH:mm',
    showTime: true,
    label: '',
    initialiseNull: false,
    labelStyle: {},
    style: {}
};

AntDateTimePicker.propTypes = {
    placeholder: PropTypes.string,
    dateFormat: PropTypes.string,
    defaultTime: PropTypes.string,
    label: PropTypes.string,
    showTime: PropTypes.bool,
    initialiseNull: PropTypes.bool,
    labelStyle: PropTypes.object,
    style: PropTypes.object,

};

export default AntDateTimePicker;
