/* global FormData:true */
import axios from 'axios';
import qs from 'qs';
import lockr from 'lockr';
import _ from 'underscore';

// load mock data if enabled
import './mock';

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
    DEBUG,
    API_URL_DEV,
    API_URL,
    GOOGLE_MAPS_KEY
} = require('../constants').default;

const API_BASE_URL = DEBUG ? API_URL_DEV : API_URL;

// defaults for axios
const api = axios.create({
    baseURL: API_BASE_URL
});
// api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// api.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded';
api.interceptors.request.use((config) => {
    // Do something before request is sent
    console.log('%c Making Request:', 'color: #4CAF50; font-weight: bold', config);
    return config;
}, (error) => {
    // Do something with request error
    console.log('%c Request Error:', 'color: #EC6060; font-weight: bold', error);
    return Promise.reject(error);
});
// api.interceptors.response.use((response) => {
//     console.log('%c Response Success:', 'color: #4CAF50; font-weight: bold', response);
//     return response;
// }, (error) => {
//     console.log('%c Response Error:', 'color: #EC6060; font-weight: bold', error);
//     return Promise.reject(error);
// });

export function fetchApi(opts, headers) {
    const data = (opts.method === 'get' || opts.method === 'GET') ? {} : opts.body; // qs.stringify(opts.body);
    const responseType = (opts.responseType ? opts.responseType : 'json');
    return api({
        method: opts.method,
        url: opts.url,
        params: opts.params,
        data,
        headers,
        responseType
    });
}

export function fetchApiAuth(opts) {
    return new Promise((resolve, reject) => {
        const authKey = lockr.get(AUTH_KEY);

        if (_.isUndefined(authKey)) {
            reject('not-authorised');
        } else {
            const authDate = new Date();
            lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
            const myOpts = opts;
            myOpts.params = myOpts.params || {};
            // myOpts.params['access-token'] = authKey;
            const headers = {};
            headers.Authorization = `Bearer ${authKey}`;

            resolve(fetchApi(opts, headers));
        }
    });
}

export function locationData(name) {
    return axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${name}&key=${GOOGLE_MAPS_KEY}`);
}
