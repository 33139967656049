import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_AGENCIES_REQUEST: null,
    GET_AGENCIES_SUCCESS: null,
    GET_AGENCIES_FAILURE: null,
});

export const {
    getAgenciesRequest,
    getAgenciesSuccess,
    getAgenciesFailure
} = createActions(
    constants.GET_AGENCIES_REQUEST,
    constants.GET_AGENCIES_SUCCESS,
    constants.GET_AGENCIES_FAILURE
);
