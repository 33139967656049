/* global document:true */

import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
// import AdminLayout from './components/AdminLayout';
// import EmptyLayout from './components/EmptyLayout';
// import Dashboard from './pages/Dashboard';
// import About from './pages/About';
// import Contact from './pages/Contact';
// import Index from './pages/NotFound';
import Routes from './core/utils/routes';
import history from './core/utils/history';


import './styles/style.css';

/**
 * ## Actions
 *  The necessary actions for dispatching our bootstrap values
 */
import { setVersion } from './core/global/globalActions';

/**
 *  The version of the app but not displayed yet
 */
import pack from '../package.json';

/**
 * ### configureStore
 */
import configureStore from './core/store';

const globalStore = configureStore({});
const { store, persistor } = globalStore;

const VERSION = pack.version;

store.dispatch(setVersion(VERSION));

// const scrollToTop = () => {
//     const pusher = document.getElementById('app');
//     if (pusher !== null) {
//         pusher.scrollIntoView();
//     }
// };

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router history={history} basename="/">
                <Routes />
            </Router>
            {/* <BrowserRouter onUpdate={scrollToTop}>
                {Routes}
                <div>
                    <Route component={EmptyLayout}>
                        <Route path="/" component={Dashboard} />
                        <Route path="about" component={About} />
                    </Route>
                    <Route component={AdminLayout}>
                        <Route path="/admin" component={Contact}>
                            <Route path="contact" component={About} />
                        </Route>
                    </Route>
                    <Route path="*" component={NotFound} />
                </div>
            </BrowserRouter> */}
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
