import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Row, Col, Icon } from 'antd';
import { connect } from 'react-redux';
import { isEmpty } from 'underscore';

import * as userActions from '../../../../core/user/userActions';
import './profileImagesList.scss';

const ProfileImagesList = ({ actions, user  }) =>  {

    let imagesList = <p style={{ textAlign: 'center', marginBottom: 15 }}>You do not have any images uploaded yet!</p>;


    if (!isEmpty(user.singleUser.profilePicturesList)) {
        imagesList = user.singleUser.profilePicturesList.map(item => (
            <Col key={`profile picture ${item.id}`} span={8}>
                <div className="images-list-item">
                    <div className="remove-image-button" title="Remove image" onClick={() => actions.removeImageRequest({ imageId: item.id })}>
                        <Icon type="close" className="close-icon" />
                    </div>
                    <div className="images-list-item">
                        <img src={item.image} alt={`Profile picture ${item.id}`} />
                    </div>
                </div>
            </Col>
        ));
    }

    return (
        <div className="profile-images-list">
            <Row>
                {imagesList}
            </Row>
        </div>
    );
}

ProfileImagesList.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        user: state.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileImagesList);
