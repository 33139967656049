import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { isEmpty } from 'underscore';

class AntInput extends Component {
// const AntInput = ({label, name, placeholder, required}) => (
    updateValue(currentValue) {
        const { input: { value, onChange } } = this.props;
        onChange(currentValue);
    }

    render() {
        const { input: { value, onChange, props }, label, type, name, placeholder, required, title, pattern, style } = this.props;
        return (<div className="ant-row ant-form-item" style= {style}>
            {!isEmpty(label) ?
                <div className="ant-form-item-label">
                    <label htmlFor={name}>
                        {label}
                    </label>
                </div>
                : null
            }
            <span className="ant-form-item-children">
                <Input
                    name={name}
                    type={type}
                    style={{ width: '100%' }}
                    onChange={this.updateValue.bind(this)}
                    className="performance-license-input-text event-field"
                    placeholder={placeholder}
                    required={required}
                    title={title}
                    pattern={pattern}
                    value={value} />
            </span>
        </div>
        );
    }
}
AntInput.defaultProps = {
    placeholder: '',
    label: '',
    required: false,
    type: 'text',
    style: {}
};
AntInput.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
};
export default AntInput;
