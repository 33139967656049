import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_CASTINGS_REQUEST: null,
    GET_CASTINGS_SUCCESS: null,
    GET_CASTINGS_FAILURE: null,
});

export const {
    getCastingsRequest,
    getCastingsSuccess,
    getCastingsFailure
} = createActions(
    constants.GET_CASTINGS_REQUEST,
    constants.GET_CASTINGS_SUCCESS,
    constants.GET_CASTINGS_FAILURE
);
