import React from 'react';
import Helmet from 'react-helmet';
import { Modal } from 'antd';

import LoginForm from '../../components/LoginForm';
import ImageLogo from '../../assets/images/logo.png';

export default function () {
    return (
        <div className="page-wrap login">
            <Helmet title="Login" />
            <div className="login-logo-block">
                <img src={ImageLogo} alt="change icon" className="menu-icon top" style={{ marginLeft: '0px' }} />
            </div>
            <LoginForm />
        </div>
    );
}
