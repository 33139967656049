import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { isEmpty } from 'underscore';

class AntTextArea extends Component {
// const AntTextArea = ({label, name, placeholder, required}) => (
    updateValue(currentValue) {
        const { input: { value, onChange } } = this.props;
        onChange(currentValue);
    }

    render() {
        const { input: { value, onChange, props }, label, type, name,rows, placeholder, required } = this.props;
        const { TextArea } = Input;

        return (<div className="ant-row ant-form-item">
            {!isEmpty(label) ?
                <div className="ant-form-item-label">
                    <label htmlFor={name}>
                        {label}
                    </label>
                </div>
                : null
            }

            <TextArea
                value={value}
                required={required}
                name={name}
                placeholder={placeholder}
                className="performance-license-input-text event-field"
                onChange={this.updateValue.bind(this)}
                style={{ paddingLeft: '15px', paddingRight: '15px' }}
                rows={rows} />
        </div>
        );
    }
}
AntTextArea.defaultProps = {
    placeholder: '',
    label: '',
    required: false,
    rows: 4
};
AntTextArea.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    rows: PropTypes.number
};
export default AntTextArea;
