import React from 'react';
import Helmet from 'react-helmet';
import { size } from 'underscore';

import ProfileForm from './forms/ProfileForm';
import Loading from '../../elements/Loading';

export default function () {
    const { user } = this.props;

    return (
        <div className="ui container general-flex-content">
            {user.isFetching ? <Loading /> : null}
            <ProfileForm
                enableReinitialize
                onSubmit={this.updateProfile.bind(this)}
                options={user.options}
                initialValues={user.singleUser.profile}
                imagesCount={size(user.singleUser.profilePicturesList)} />
        </div>
    );
}
