/* global window:true */
import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Layout, Button, Menu, Icon } from 'antd';
import lockr from 'lockr';

import ImageLogo from '../../assets/images/logo.png';

import './header.css';

const {
    Header
} = Layout;

const {
    DEBUG,
    SERVER_URL_DEV,
    SERVER_URL,
    AUTH_KEY
} = require('../../core/constants').default;

const SERVER_BASE_URL = DEBUG ? SERVER_URL_DEV : SERVER_URL;

export default function () {
    const { location } = this.props;
    return (
        <Header className="main-header">


            <Row className="logo-row">
                <Col span={6} className="left-col">
                    <Button type="link" icon="logout" onClick={this.doLogoutRequest} className="general-link">
                        Logout
                    </Button>
                </Col>
                <Col span={12} className="middle-col">
                    <img src={ImageLogo} alt="change icon" className="menu-icon top" style={{ marginLeft: '0px' }} />
                </Col>
                <Col span={6} className="right-col">
                    <Button type="link" icon="download" onClick={() => window.open(`${SERVER_BASE_URL}account/view-card-pdf?auth_key=${lockr.get(AUTH_KEY)}`, '_blank')} className="general-link">
                        Download Comp Card
                    </Button>
                </Col>
            </Row>

            <div className="header-menu">
                <Menu selectedKeys={[location.pathname]} mode="horizontal">
                    <Menu.Item key="/admin/castings">
                        <Link className="item" to="/admin/castings">
                            Castings
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/admin/agencies">
                        <Link className="item" to="/admin/agencies">
                            Agencies
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/admin/profile">
                        <Link className="item" to="/admin/profile">
                            Your Profile
                        </Link>
                    </Menu.Item>
                </Menu>
            </div>
        </Header>
    );
}
