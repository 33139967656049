import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Upload, Button } from 'antd';
import { connect } from 'react-redux';

import * as userActions from '../../../../core/user/userActions';

const ProfileImageUpload = ({  input: { value, onChange, props }, width, title, subtitle, encrypt, actions  }) =>  {

    const Dragger = Upload.Dragger;

    const uploadProps = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        customRequest({ onSuccess, onError, file }) {
            actions.uploadImageRequest(file);
        }
    };

    return (
        <Upload {...uploadProps} style={{ width }}>
            <Button className="file-upload-button theme-secondary-button">
                Add Image
            </Button>
        </Upload>
    );
}

ProfileImageUpload.defaultProps = {
    multiple: true,
    width: '100%',
    title: 'Click or drag file to this area to upload',
    subtitle: 'Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files',
};

ProfileImageUpload.propTypes = {
    multiple: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    width: PropTypes.string,
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileImageUpload);
