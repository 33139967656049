import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    DELETE_TOKEN_REQUEST: null,
    DELETE_TOKEN_SUCCESS: null,

    SAVE_TOKEN_REQUEST: null,
    SAVE_TOKEN_SUCCESS: null,

    LOGIN_REQUEST: null,
    LOGIN_SUCCESS: null,
    LOGIN_FAILURE: null,

    LOGOUT_REQUEST: null,
    LOGOUT_SUCCESS: null,
    LOGOUT_FAILURE: null,

    UPDATE_PROFILE_REQUEST: null,
    UPDATE_PROFILE_SUCCESS: null,
    UPDATE_PROFILE_FAILURE: null,

    UPLOAD_IMAGE_REQUEST: null,
    UPLOAD_IMAGE_SUCCESS: null,
    UPLOAD_IMAGE_FAILURE: null,

    REMOVE_IMAGE_REQUEST: null,
    REMOVE_IMAGE_SUCCESS: null,
    REMOVE_IMAGE_FAILURE: null,

    RESET_PASSWORD_REQUEST: null,
    RESET_PASSWORD_SUCCESS: null,
    RESET_PASSWORD_FAILURE: null,
});

export const {
    deleteTokenRequest,
    deleteTokenSuccess
} = createActions(
    constants.DELETE_TOKEN_REQUEST,
    constants.DELETE_TOKEN_SUCCESS
);

export const {
    saveTokenRequest,
    saveTokenSuccess
} = createActions(
    constants.SAVE_TOKEN_REQUEST,
    constants.SAVE_TOKEN_SUCCESS
);

export const {
    loginRequest,
    loginSuccess,
    loginFailure
} = createActions(
    constants.LOGIN_REQUEST,
    constants.LOGIN_SUCCESS,
    constants.LOGIN_FAILURE
);

export const {
    logoutRequest,
    logoutSuccess,
    logoutFailure
} = createActions(
    constants.LOGOUT_REQUEST,
    constants.LOGOUT_SUCCESS,
    constants.LOGOUT_FAILURE
);

export const {
    updateProfileRequest,
    updateProfileSuccess,
    updateProfileFailure
} = createActions(
    constants.UPDATE_PROFILE_REQUEST,
    constants.UPDATE_PROFILE_SUCCESS,
    constants.UPDATE_PROFILE_FAILURE
);

export const {
    uploadImageRequest,
    uploadImageSuccess,
    uploadImageFailure
} = createActions(
    constants.UPLOAD_IMAGE_REQUEST,
    constants.UPLOAD_IMAGE_SUCCESS,
    constants.UPLOAD_IMAGE_FAILURE
);

export const {
    removeImageRequest,
    removeImageSuccess,
    removeImageFailure
} = createActions(
    constants.REMOVE_IMAGE_REQUEST,
    constants.REMOVE_IMAGE_SUCCESS,
    constants.REMOVE_IMAGE_FAILURE
);

export const {
    resetPasswordRequest,
    resetPasswordSuccess,
    resetPasswordFailure
} = createActions(
    constants.RESET_PASSWORD_REQUEST,
    constants.RESET_PASSWORD_SUCCESS,
    constants.RESET_PASSWORD_FAILURE
);
